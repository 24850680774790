<template>
  <h4 :class="['quote', {'quote--align-left' : align === 'left'}, 
												{'quote--align-right' : align === 'right'}]">
		<slot></slot>
	</h4>
</template>

<script>
export default {
	props: {
		align: String,
	},
}

</script>

<style lang="scss" scoped>
.quote{
	font-family: var(--quote-font-family);
	color: var(--quote-color);
	font-size: var(--quote-font-size);
	line-height: var(--quote-line-height);
	font-weight: var(--quote-font-weight);
	margin: var(--quote-margin);
	padding: var(--quote-padding);
	text-align: var(--quote-text-align);
	position: relative;
}
.quote::before{
	content: '';
	top: 0;
	left: 50%;
	position: absolute;
	width: var(--quote-decoration-width);
	height: var(--quote-decoration-height);
	background-color: var(--quote-decoration-background);
	margin-left: var(--quote-decoration-left);
}
.quote::after{
	content: '';
	bottom: 0;
	left: 50%;
	position: absolute;
	width: var(--quote-decoration-width);
	height: var(--quote-decoration-height);
	background-color: var(--quote-decoration-background);
	margin-left: var(--quote-decoration-left);
}

.quote--align-left{
	float: left;
	width: var(--quote-left-width);
	margin: var(--quote-left-margin);
}
.quote--align-right{
	float: right;
	width: var(--quote-right-width);
	margin: var(--quote-right-margin);
}
</style>

<!-- Used in : AR, DS -->